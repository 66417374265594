import React, { useState } from 'react'
import Img from 'gatsby-image'
import youtubePlayButton from './youtubePlayButton.gif'
import PropTypes from 'prop-types'
import styles from './videoThumb.module.css'

const VideoThumb = ({ data, onButtonClick }) => {
  const [isHovered, setHover] = useState(false)
  if (data) {
    return (
      <div
        className={styles.thumb}
        onMouseEnter={() => {
          setHover(true)
        }}
        onMouseLeave={() => {
          setHover(false)
        }}
        onClick={() => {
          onButtonClick()
        }}
      >
        <div className={styles.thumbImageContainer}>
          {isHovered ? (
            <div className={styles.thumbOverlay}>
              <img
                alt="YouTube Play Icon"
                className={styles.youtubePlay}
                src={youtubePlayButton}
              />
            </div>
          ) : null}
          <Img
            fluid={data.localThumbnail.childImageSharp.fluid}
            alt="YouTube Video"
          />
        </div>
        <div className={styles.videoDescription}>{data.title}</div>
      </div>
    )
  }
  return null
}

VideoThumb.propTypes = {
  onButtonClick: PropTypes.func,
  data: PropTypes.object,
}

export default VideoThumb
