import React, { useState, useRef } from 'react'
import { BlogData } from '../contexts/blogData/blogData'
import { CampingData } from '../contexts/campingData/campingData'
import { LodgingData } from '../contexts/lodgingData/lodgingData'
import SEO from '../components/seo'
import SideBar from '../components/sideBar/SideBar'
import TwoColumnLayout from '../components/twoColumnLayout/TwoColumnLayout'
import VideoCategorySelector from '../components/Selectors/VideoCategorySelector/VideoCategorySelector'
import { VideoData } from '../contexts/videoData/videoData'
import Videos from '../components/Videos/Videos'
import PropTypes from 'prop-types'

const VideosPage = ({ location }) => {
  const myRef = useRef(null)
  const videoId = location.state ? location.state.videoId : null
  const [category, setCategory] = useState(
    location.state ? location.state.category : null
  )
  const [isIframeLoaded, setIsiFrameLoaded] = useState(false)

  const oniFrameLoaded = iFrameStatus => {
    setIsiFrameLoaded(iFrameStatus)
  }

  const onSetCategory = category => {
    setCategory(category)
  }

  const onButtonClick = () => {
    myRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  return (
    <div ref={myRef}>
      <TwoColumnLayout>
        <SEO
          title="Videos"
          description="All Cactus Atlas Videos, filterable by category."
          url="https://cactusatlas.com/videos/"
          type="article"
        />
        <BlogData>
          <CampingData>
            <LodgingData>
              <VideoData>
                <Videos
                  category={category}
                  selectedVideo={videoId}
                  isIframeLoaded={isIframeLoaded}
                  onSetCategory={category => onSetCategory(category)}
                  oniFrameLoaded={iFrameStatus => oniFrameLoaded(iFrameStatus)}
                  onButtonClick={() => onButtonClick()}
                />
              </VideoData>
              <SideBar>
                <VideoCategorySelector
                  currentCategory={category}
                  onSetCategory={category => onSetCategory(category)}
                  oniFrameLoaded={iFrameStatus => oniFrameLoaded(iFrameStatus)}
                  onButtonClick={() => onButtonClick()}
                />
              </SideBar>
            </LodgingData>
          </CampingData>
        </BlogData>
      </TwoColumnLayout>
    </div>
  )
}

VideosPage.propTypes = {
  location: PropTypes.object,
  search: PropTypes.object,
}

export default VideosPage
