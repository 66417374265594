import React from 'react'
import { get } from 'lodash'
import VideoThumb from './VideoThumb/VideoThumb'
import PropTypes from 'prop-types'
import styles from './videoThumbs.module.css'

const VideoThumbs = ({
  filteredVideos,
  currentVideo,
  onButtonClick,
  onSetCurrentVideo,
  oniFrameLoaded,
  onSetCategory,
}) => {
  const videoThumbs = filteredVideos.filter(
    video => get(video, 'videoId') !== get(currentVideo, 'videoId')
  )

  return (
    <div className={styles.thumbs}>
      {videoThumbs.map((thumb, index) => (
        <div
          onClick={() => {
            onSetCategory(null)
            onSetCurrentVideo(thumb)
          }}
          key={index}
        >
          <VideoThumb
            onButtonClick={() => {
              onButtonClick()
              oniFrameLoaded(false)
            }}
            data={thumb}
          />
        </div>
      ))}
    </div>
  )
}

VideoThumbs.propTypes = {
  filteredVideos: PropTypes.array,
  currentVideo: PropTypes.object,
  onButtonClick: PropTypes.func,
  onSetCurrentVideo: PropTypes.func,
  oniFrameLoaded: PropTypes.func,
  onSetCategory: PropTypes.func,
}
export default VideoThumbs
