import { get } from 'lodash'
export const computeVideoData = (blogData, videosData) =>
  blogData.map(blog => {
    const video = get(
      videosData.find(video => {
        return video.node.videoId === blog.videoId
      }),
      'node'
    )
    if (video) {
      return {
        ...blog,
        videoTitle: video.title,
        publishedAt: video.publishedAt,
        privacyStatus: video.privacyStatus,
        localThumbnail: video.localThumbnail,
        thumbnail: video.thumbnail,
      }
    }
  })

export const filterVideosByCategory = (allVideos, category) =>
  allVideos.filter(video => {
    if (video && video.categories.find(y => y === category)) {
      return video
    }
  })

export const computeInitialVideo = (filteredVideos, selectedVideo) => {
  return filteredVideos.find(video => get(video, 'videoId') === selectedVideo)
}
