import React, { useContext, useState } from 'react'
import BlogContext from '../../contexts/blogData/blogData'
import CampingContext from '../../contexts/campingData/campingData'
import { computeUniqueCategories } from '../../helpers/helpers'
import {
  computeInitialVideo,
  computeVideoData,
  filterVideosByCategory,
} from './helpers'
import LodgingContext from '../../contexts/lodgingData/lodgingData'
import MediaQuery from '../../components/sharedComponents/MediaQuery/MediaQuery'
import MobileCategorySelector from '../../components/Selectors/MobileCategorySelector/MobileCategorySelector'

import Title from '../sharedComponents/Title/Title'
import VideoContext from '../../contexts/videoData/videoData'
import VideoThumbs from './VideoThumbs/VideoThumbs'
import YouTubeiFrame from './YouTubeiFrame/YouTubeiFrame'
import PropTypes from 'prop-types'

const queries = {
  mobile: '(max-width: 999px)',
  desktop: '(min-width: 1000px)',
}

const Videos = ({
  selectedVideo,
  isIframeLoaded,
  category,
  onSetCategory,
  oniFrameLoaded,
  onButtonClick,
}) => {
  const matchPoints = MediaQuery(queries)
  const videosData = useContext(VideoContext)
  const blogData = useContext(BlogContext)
  const campingData = useContext(CampingContext)
  const lodgingData = useContext(LodgingContext)
  const allVideos = [
    ...computeVideoData(lodgingData, videosData),
    ...computeVideoData(campingData, videosData),
    ...computeVideoData(blogData, videosData),
  ]

  const filteredVideos = category
    ? filterVideosByCategory(allVideos, category)
    : allVideos

  const [currentVideo, setCurrentVideo] = useState(
    selectedVideo
      ? computeInitialVideo(filteredVideos, selectedVideo)
      : filteredVideos[0]
  )

  const currentCategoryVideo = category ? filteredVideos[0] : null

  const onSetCurrentVideo = video => {
    setCurrentVideo(video)
  }
  const uniqueCategories = computeUniqueCategories([
    ...useContext(BlogContext),
    ...useContext(CampingContext),
    ...useContext(LodgingContext),
  ])

  const title = category ? `${category} Videos` : 'All Videos'
  return (
    <div>
      {matchPoints && matchPoints.mobile ? (
        <MobileCategorySelector
          category={category}
          onSetCategory={category => onSetCategory(category)}
          onButtonClick={() => onButtonClick()}
          uniqueCategories={uniqueCategories}
        />
      ) : null}
      {matchPoints && matchPoints.desktop ? <Title title={title} /> : null}
      <YouTubeiFrame
        currentVideo={currentCategoryVideo || currentVideo}
        isIframeLoaded={isIframeLoaded}
        oniFrameLoaded={iFrameStatus => oniFrameLoaded(iFrameStatus)}
      />
      <VideoThumbs
        filteredVideos={filteredVideos}
        currentVideo={currentCategoryVideo || currentVideo}
        onButtonClick={() => onButtonClick()}
        onSetCurrentVideo={video => onSetCurrentVideo(video)}
        oniFrameLoaded={iFrameStatus => oniFrameLoaded(iFrameStatus)}
        onSetCategory={category => onSetCategory(category)}
      />
    </div>
  )
}

Videos.propTypes = {
  selectedVideo: PropTypes.string,
  category: PropTypes.string,
  isIframeLoaded: PropTypes.bool,
  onSetCategory: PropTypes.func,
  oniFrameLoaded: PropTypes.func,
  onButtonClick: PropTypes.func,
}

export default Videos
