import React, { useContext } from 'react'
import BlogContext from '../../../contexts/blogData/blogData'
import CampingContext from '../../../contexts/campingData/campingData'
import { computeUniqueCategories } from '../../../helpers/helpers'
import LodgingContext from '../../../contexts/lodgingData/lodgingData'
import PropTypes from 'prop-types'
import styles from '../categorySelector.module.css'

const VideoCategory = ({
  category,
  selectedCategory,
  onSetCategory,
  oniFrameLoaded,
  onButtonClick,
}) => {
  return (
    <li>
      <button
        className={`${styles.categoryLink} ${
          selectedCategory === category.value ? styles.activeCategory : null
        }`}
        onClick={() => {
          onSetCategory(category.value)
          oniFrameLoaded(false)
          onButtonClick()
        }}
      >
        {category.display}
      </button>
    </li>
  )
}

const VideoCategorySelector = ({
  currentCategory,
  onSetCategory,
  oniFrameLoaded,
  onButtonClick,
}) => {
  const uniqueCategories = computeUniqueCategories([
    ...useContext(BlogContext),
    ...useContext(CampingContext),
    ...useContext(LodgingContext),
  ])
  const categories = uniqueCategories.map((category, index) => (
    <VideoCategory
      key={index}
      category={category}
      selectedCategory={currentCategory}
      onSetCategory={category => onSetCategory(category)}
      oniFrameLoaded={iFrameStatus => oniFrameLoaded(iFrameStatus)}
      onButtonClick={() => onButtonClick()}
    />
  ))

  return (
    <div>
      <h3 className={styles.categoriesHeader}>Categories</h3>
      <ul>{categories}</ul>
    </div>
  )
}

VideoCategory.propTypes = {
  category: PropTypes.object,
  selectedCategory: PropTypes.string,
  onSetCategory: PropTypes.func,
  oniFrameLoaded: PropTypes.func,
  onButtonClick: PropTypes.func,
}

VideoCategorySelector.propTypes = {
  currentCategory: PropTypes.string,
  onSetCategory: PropTypes.func,
  oniFrameLoaded: PropTypes.func,
  onButtonClick: PropTypes.func,
}
export default VideoCategorySelector
