import React from 'react'
import Img from 'gatsby-image'
import short from 'short-uuid'
import Spinner from '../../sharedComponents/Spinner/Spinner'
import PropTypes from 'prop-types'
import styles from './youtubeiFrame.module.css'

//TODO: Figure out a better solution than forcing iframe reload (See GIT History for why)

const YouTubeiFrame = ({ currentVideo, isIframeLoaded, oniFrameLoaded }) => {
  if (currentVideo) {
    return (
      <div className={styles.videoContainer}>
        <div
          className={`${styles.thumbOverlay} ${
            isIframeLoaded ? styles.hideThumb : styles.showThumb
          }`}
        >
          <Spinner />
        </div>
        <Img
          fluid={currentVideo.localThumbnail.childImageSharp.fluid}
          className={styles.thumbImage}
          alt="YouTube Video"
        />
        <iframe
          key={short.generate()}
          onLoad={() => {
            oniFrameLoaded(true)
          }}
          title="Featured Cactus Atlas Video"
          src={`https://www.youtube.com/embed/${currentVideo.videoId}?modestbranding=1&autohide=1&controls=0`}
          frameBorder="0"
          allowFullScreen
          allow="autoplay"
          className={`${styles.video} ${
            isIframeLoaded ? styles.showThumb : styles.hideThumb
          }`}
        />
      </div>
    )
  }
  return <div className={styles.noVideoFound}>Sorry, No Video Found</div>
}

YouTubeiFrame.propTypes = {
  currentVideo: PropTypes.object,
  isIframeLoaded: PropTypes.bool,
  oniFrameLoaded: PropTypes.func,
}

export default YouTubeiFrame
